<template>
  <div>
    <v-card
      class="mx-auto mb-5 v-card v-sheet theme--light"
      style="background-color: #4d81c0 !important"
      :style="$vuetify.breakpoint.mobile ? 'max-width: 344px;' : ''"
    >
      <v-card-title class="white--text"
        ><v-icon color="white" large left> mdi-newspaper-variant </v-icon
        >Вести</v-card-title
      >
      <v-card-text>
        <div class="news-wrapper" v-if="showNews">
          <v-card
            v-for="article in articles"
            :key="article.title"
            class="add-card my-3 pt-3"
            hover
            data-aos="zoom-in"
            data-aos-easing="ease"
          >
            <v-img
              height="150px"
              alt="sldprokuplje"
              contain
              :aspect-ratio="16/9"

              thumbnail
              :src=article.url
            ></v-img>
            <v-card-title>{{ article.title }}</v-card-title>
            <v-card-text>
              {{ article.description }}
            </v-card-text>
            <v-card-actions>
              <v-chip small color="secondary" class="white--text">
                {{ new Date(article.date).toLocaleDateString() }}
              </v-chip>

              <v-spacer></v-spacer>
              <v-btn small replace color="info" @click="openDialog(article)"
                >Прочитај</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
            <v-card
            v-else
            max-width="200px"
            class="add-card my-3 mx-auto"
            hover
            data-aos="zoom-in"
            data-aos-easing="ease"
          >
            <v-card-text class="text-center">
              Тренутно нема вести
            </v-card-text>
          </v-card>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      width="50%"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      max-width="80%"
    >
      <v-card>
        <v-img
          height="150px"
          contain
          style="background-color:#1976d2"
          alt="sldprokuplje"
          :src=selectedArticle.url        >
          <v-card-title class="white--text">{{
            selectedArticle.title
          }} <v-spacer></v-spacer> <v-btn @click="dialog = false" color="blue darken-1" text >
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn></v-card-title></v-img
        >
        <v-divider></v-divider>
        <v-card-text style="white-space: pre-wrap;" height="200px">{{ selectedArticle.text }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog()">
            Затвори
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db } from "@/main";
export default {
  name: "Vesti",
  data() {
    return {
      articles: [],
      selectedArticle: {},
      dialog: false,
      showNews: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let snapshot = await db.collection("article").get();
      let arr = [];
      snapshot.forEach((doc) => {
        let appData = doc.data();
        appData.id = doc.id;
        arr.push(appData);
      });
      const sortedArticles = arr.sort((a, b) => b.date - a.date);
      this.articles = sortedArticles;
      if (this.articles.length > 0) {
        this.showNews = true;
      } else {
        this.showNews = false;
      }
    },
    openDialog(article) {
      if (article) {
        this.selectedArticle = article;
        this.dialog = true;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.selectedArticle = {};
    },
  },
};
</script>
<style scoped>
.news-wrapper {
  display: grid;
  justify-content: center;
}
/* .card-wrapper {
  background-color: #4d81c0 !important;
} */
</style>